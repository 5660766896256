import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import {CACHE_SIZE_UNLIMITED, DocumentData, enableMultiTabIndexedDbPersistence, getFirestore, initializeFirestore, QueryDocumentSnapshot, SnapshotOptions, Timestamp, WithFieldValue, doc} from 'firebase/firestore';
import { getFunctions } from "firebase/functions";
import { getStorage } from 'firebase/storage';
import { getMessaging, Messaging } from "firebase/messaging";
import { Job, ProcessSetupLog, ProcessType } from '../functions/src/types/Job';
import {luxonDateToTimestamp, TimestampToLuxonDate} from '../utils/date';
import { DailyReport } from '../functions/src/types/Report';
import { Arrangement } from '../functions/src/types/Arrangement';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

export const docConverter = {
  toFirestore(doc: WithFieldValue<any>): DocumentData {
      const { id, ...docWithoutId } = doc;
      return docWithoutId;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): {
    id: string,
    [x: string]: any
  } {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ...data,
    };
  },
};

export const jobConverter = {
  toFirestore(job: (Job & {id: string})){
    const { id, deadline, approvedOn, ...jobWithoutId } = job;
    return {
      ...jobWithoutId,
      process: job?.process?.map((p) => ({
          ...p,
          timestamp: luxonDateToTimestamp(p.timestamp)
      })),
      processSetupLog: job?.processSetupLog?.map((p) => ({
        ...p,
        ...p.startDate?{ startDate: luxonDateToTimestamp(p.startDate) } : {},
        ...p.endDate?{ endDate: luxonDateToTimestamp(p.endDate) } : {},
        ...p.startWork?{ startWork: luxonDateToTimestamp(p.startWork) } : {},
        ...p.endWork?{ endWork: luxonDateToTimestamp(p.endWork) } : {},
      })),
      ...deadline?{deadline: luxonDateToTimestamp(deadline)}:{},
      ...job?.estMaterialDate?{estMaterialDate: luxonDateToTimestamp(job!.estMaterialDate)}:{},
      ...job?.prodDeadline?{prodDeadline: luxonDateToTimestamp(job!.prodDeadline)}:{},
      ...approvedOn?{approvedOn: luxonDateToTimestamp(approvedOn)}:{},
    }
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): (Job & {id: string}) {
    const data = snapshot.data(options) as Omit<Job, 'deadline' | 'process' | 'estMaterialDate' | 'prodDeadline' | 'processSetupLog' | `processSetupLog.${number}`> & 
      ({
        process: { 
          status: ProcessType; 
          timestamp: Timestamp; 
          imageURL: string; 
          remark: string; }[], 
        processSetupLog: (Omit<NonNullable<ProcessSetupLog>, 'startDate' | 'endDate'> & {
          startDate: Timestamp, 
          endDate: Timestamp,
          startWork?: Timestamp,
          endWork?: Timestamp
        })[],
        deadline: Timestamp, 
        estMaterialDate: Timestamp, 
        prodDeadline: Timestamp, 
        approvedOn?: Timestamp,
        createdOn: Timestamp
      });

    return {
      ...data,
      id: snapshot.id,
      process: data?.process?.map((p) => ({
          ...p,
          timestamp: TimestampToLuxonDate(p.timestamp)
      })),
      //@ts-ignore
      processSetupLog: data?.processSetupLog?.map((p) => ({
        ...p,
        ...p.startDate ? { startDate: TimestampToLuxonDate(p.startDate) } : undefined,
        ...p.endDate ? { endDate: TimestampToLuxonDate(p.endDate) } : undefined,
        ...p.startWork ? { startWork: TimestampToLuxonDate(p.startWork) } : undefined,
        ...p.endWork ? { endWork: TimestampToLuxonDate(p.endWork) } : undefined,
      })),
      deadline: TimestampToLuxonDate(data?.deadline),
      estMaterialDate: data?.estMaterialDate?TimestampToLuxonDate(data!.estMaterialDate):undefined,
      prodDeadline: data?.prodDeadline?TimestampToLuxonDate(data!.prodDeadline):undefined,
      ...data?.approvedOn?{approvedOn: TimestampToLuxonDate(data!.approvedOn)}:{},
      createdOn: TimestampToLuxonDate(data.createdOn),
    }
  }
}

export const arrangementConverter = {
  toFirestore(arrangement : ( Arrangement & {id: string})){
    const { id, deadline, start, createdAt, ...arrangementWithoutId } = arrangement;
    return {
      ...arrangementWithoutId,
      deadline: luxonDateToTimestamp(deadline),
      start: luxonDateToTimestamp(start),
      createdAt: luxonDateToTimestamp(createdAt),
    }
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): (Arrangement & {id: string}) {
    const data = snapshot.data(options) as Omit<Arrangement, 'deadline' | 'start' | 'createdAt'> & 
                  {deadline: Timestamp, start: Timestamp, createdAt: Timestamp};
    return {
      ...data,
      id: snapshot.id,
      deadline: TimestampToLuxonDate(data?.deadline),
      start: TimestampToLuxonDate(data?.start),
      createdAt: TimestampToLuxonDate(data?.createdAt),
    }
  }
}

export const reportConverter = {
  toFirestore(job: (DailyReport & {id: string})){
    const { id, createdAt, ...jobWithoutId } = job;
    return {
      ...jobWithoutId,
      createdAt: luxonDateToTimestamp(createdAt),
    }
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): (DailyReport & {id: string}) {
    const data = snapshot.data(options) as Omit<DailyReport, 'createdAt'> & {createdAt: Timestamp};
    return {
      ...data,
      id: snapshot.id,
      createdAt: TimestampToLuxonDate(data?.createdAt)
    }
  }
}




// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
initializeFirestore(firebase, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  ignoreUndefinedProperties: true,
});
// Get all the services we'll use in this app, then export them
const auth = getAuth(firebase)
const storage = getStorage(firebase)
const db = getFirestore(firebase);

const rtdb = getDatabase(firebase);
const functions = getFunctions(firebase);
const now = Timestamp.now();
let messaging: Messaging | undefined = undefined;

if(process.browser && typeof window !== 'undefined' && 'Notification' in window){
    messaging = getMessaging(firebase)
}

// enableMultiTabIndexedDbPersistence(db)
//   .catch((err) => {
//       console.error(err)
//   });
console.log(firebase.name ? 'Firebase Mode Activated!' : 'Firebase not working :(');
console.log('Firebase proj: ', process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID)


export { firebase, auth, storage, db, rtdb, functions, now, messaging}