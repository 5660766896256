import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "@firebase/auth";
import { User } from "firebase/auth";
import { onDisconnect, onValue, ref, serverTimestamp, set, off } from "@firebase/database";
import { updateDoc, doc, onSnapshot, Timestamp } from "@firebase/firestore";
import { useRouter } from "next/dist/client/router";
import { useState, useEffect, useContext, createContext, PropsWithChildren } from "react";
import { auth, db, firebase, rtdb, messaging } from "../config/firebase";
import useLocalStorage from "./useLocalStorage";
import { UserClaims } from "../functions/src/types/User";
import { getToken } from "firebase/messaging";
import { useSnackbar } from "notistack";



const authContext = createContext<ReturnType<typeof useAuthProvider>>({
    user: null,
    userDetails: null,
    userToken: undefined,
    signIn: async (a: string, b:string) => "",
    signOut: async () => {},
    updateMessagingToken: async () => undefined,
    appInstance: Date.now(),
    getUserTokenResult: async () => undefined,
});
const { Provider } = authContext;

/**
 * @component
 * @param {props} props children that require access to the useAuth hook
 * @returns {Provider}
 */
export function AuthProvider({ children }: PropsWithChildren<{}>) {
    const auth = useAuthProvider();
    return <Provider value={auth}>{children}</Provider>;
}

/**
 * The hook which contains the current state of user authentication
 * @returns authContext
 */
export const useAuth = () => {
    return useContext(authContext);
};

let appInstance = Date.now();

/**
 * Logic required to run the auth methods for Firebase
 * @returns { user, userDetails, signInWithEmailAndPassword, signOut, initGoogleSignIn }
 */
const useAuthProvider = () => {
    const [user, setUser] = useState<User | null | undefined>(null); 
    const [userDetails, setUserDetails] = useState(null);
    const [userToken, setUserToken] = useState<UserClaims | undefined>(undefined);
    const [lastCommitted, setLastCommitted] = useLocalStorage("lastCommited", 0);  //The last committed state of our user claims document, decides if token needs to update if outdated
    const [tokenLastUpdated, setTokenLastUpdated] = useLocalStorage("tokenLastUpdated", 0);  //The last time the token was updated
    const { enqueueSnackbar } = useSnackbar();
    const router = useRouter()

    /**
     * Sign in user with a Google Account with a redirect
     */
    const signIn = (email:string, password:string ) => signInWithEmailAndPassword(auth, email, password).catch(error => enqueueSnackbar(error.message, { variant: "error" }));
    
    const updateMessagingToken = async () => {
        if(!user || !process.browser || !messaging) return;
        if(!('Notification' in window)) {
            enqueueSnackbar('Notifications not supported', { variant: "error" });
            return;
        }
        console.log('[MESSAGING] Updating Messaging Token to Server');
        const token = await getToken(messaging, { 
            vapidKey: 'BGrN7atLTxK5wwDuBxBDhkJiBmNlysQNtYtPJKGioIhz5nXfzR4IK0F5Xy1OskxaQK0BBEZmoX4Pnr0_sDbtJ6o' 
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            enqueueSnackbar('Failed to register notifications: ' + err.message, { variant: "error" })
        });
        if(!token) {
            //request for token generation
            console.log('request for token required')
            enqueueSnackbar('Notitication token not found', { variant: "error" });
            return;
        }
        await updateDoc(doc(db, `users/${user.uid}`), {
            [`messagingTokens.${token}`]: Timestamp.now()
        })
        setTokenLastUpdated(Date.now());
        enqueueSnackbar('Notification Token Updated', { variant: 'success' });
        return token;
    }

    /**
     * Get firebase user tokens with custom claims for permission use, only refreshes if is true
     * @param {boolean} refresh 
     * @returns userClaims
     */
    const getUserTokenResult = async (refresh?: boolean) => {
        if (!user) return;
        let { claims } = await user.getIdTokenResult(refresh);
        console.log("claims", claims)
        //If user on homepage, redirect to dashboard
        // if (router.asPath.startsWith('/')) {
        //     history.push('/');
        // }
        return claims
    };

    /**
     * Handles when onAuthStateChanged is called, and sets user into User State
     * @param {firebase.auth.User} user 
     */
    const handleAuthStateChanged = async (user: User | null) => {
        if (user) {
            setUser(user);
            console.log('logged in as ', user.email + " " +user.uid);
            let { claims } = await user.getIdTokenResult();
            setUserToken(claims as UserClaims);
            //if user on login page, redirect to homepage
            console.log(router.asPath)
            if (router.asPath === '/login' || router.asPath === 'login') {
                router.push('/');
            }
        }
        else {
            setUser(undefined)
            router.push('/login');
        };
    };

    //Attaches the onAuthStateChanged to listen for changes in authentication eg: login, signout etc.
    useEffect(() => {
        const unsub = onAuthStateChanged(auth, handleAuthStateChanged);
        return () => unsub();
        }, []);
    
    //Attaches user claims documents to listen for changes in user permissions, if yes update token to ensure no permission errors
    useEffect(() => {
        if (!user) return;
        //if the token was updated 2 weeks ago, update it
        if ((Date.now() - tokenLastUpdated) > 1209600000) updateMessagingToken();

        return onSnapshot(doc(db,'user_claims',user.uid), async (snap) => {
            const data = snap.data();
            
            if(!data?._lastCommitted) return;

            if (lastCommitted && !(data?._lastCommitted || {}).isEqual(lastCommitted)) {
                setUserToken(await getUserTokenResult(true) as UserClaims);
            }
            setLastCommitted(data?._lastCommitted);
        },
        error => {
            console.log(error)
        });
    }, [user?.uid]); //Only reattach if user uid is updated :(

    //Attaches the user document to listen for changes in the document
    useEffect(() => {
        if (user?.uid) {
            // Subscribe to user document on mount
            // const unsubscribe = onSnapshot(doc(db,'users',user.uid), async (doc) => {
            //     latestUserDetails = doc.data()
            //     setUserDetails(latestUserDetails)
            // })
            var userStatusDatabaseRef = ref(rtdb,'/status/' + user.uid); 
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: serverTimestamp(),
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: serverTimestamp(),
            };
            onValue(ref(rtdb,'.info/connected'), (snapshot) => {
                // If we're not currently connected, don't do anything.
                if (snapshot.val() == false) return;
                onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(function() {
                    set(userStatusDatabaseRef, isOnlineForDatabase);
                });
            }, (error) => {
                console.error(error);
            })

            return () => {
                off(ref(rtdb,'.info/connected'));
                // unsubscribe();
            }
        }
    }, [user]);

    /**
     * Signs out the current user
     * @returns null
     */
    const userSignOut = () => {
        return signOut(auth).then(() => {
            setUser(undefined);
            enqueueSnackbar('Signed Out', { variant: 'success' });
        });
    };

    return {
        user,
        userDetails,
        userToken,
        getUserTokenResult,
        appInstance,
        signOut: userSignOut,
        signIn,
        updateMessagingToken
    };
};