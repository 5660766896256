// @ts-nocheck
import React from "react";
import { AppProps } from "next/app";
import "../styles/globals.css";
import { AuthProvider } from "../hooks/useAuth";
import DateAdapter from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import muiTheme from "../config/muiTheme";
import ColorTW from "../utils/ColorTW";
import { SnackbarProvider } from 'notistack';
import 'react-medium-image-zoom/dist/styles.css'
import DialogProvider from "../hooks/useDialog";
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE)

function MyApp({ Component, pageProps }: AppProps) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        ...muiTheme,
        palette: {
          mode: "light",
          primary: {
            ...ColorTW.slate,
            main: ColorTW.slate[500] ,
            light: ColorTW.slate[300],
            dark: ColorTW.slate[700],
            contrastText: ColorTW.slate[900]+"AA",
          },
          background: {
            default: ColorTW.slate[50],
            paper: ColorTW.slate[100],
          },
          text: {
            primary: ColorTW.slate[900],
            secondary: "#B2BAC2",
            disabled: "rgba(255, 255, 255, 0.5)",
          },
          grey: {
            ...ColorTW.gray,
            A100: "#f5f5f5",
            A200: "#eeeeee",
            A400: "#bdbdbd",
            A700: "#616161",
          },
          error: {
            "50": "#FFF0F1",
            "100": "#FFDBDE",
            "200": "#FFBDC2",
            "300": "#FF99A2",
            "400": "#FF7A86",
            "500": "#FF505F",
            "600": "#EB0014",
            "700": "#C70011",
            "800": "#94000D",
            "900": "#570007",
            main: "#EB0014",
            light: "#FF99A2",
            dark: "#C70011",
            contrastText: "#fff",
          },
          success: {
            "50": "#E9FBF0",
            "100": "#C6F6D9",
            "200": "#9AEFBC",
            "300": "#6AE79C",
            "400": "#3EE07F",
            "500": "#21CC66",
            "600": "#1DB45A",
            "700": "#1AA251",
            "800": "#178D46",
            "900": "#0F5C2E",
            main: "#178D46",
            light: "#6AE79C",
            dark: "#1AA251",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          warning: {
            "50": "#FFF9EB",
            "100": "#FFF3C1",
            "200": "#FFECA1",
            "300": "#FFDC48",
            "400": "#F4C000",
            "500": "#DEA500",
            "600": "#D18E00",
            "700": "#AB6800",
            "800": "#8C5800",
            "900": "#5A3600",
            main: "#DEA500",
            light: "#FFDC48",
            dark: "#AB6800",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          secondary: {
            main: "#ce93d8",
            light: "#f3e5f5",
            dark: "#ab47bc",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          info: {
            main: "#29b6f6",
            light: "#4fc3f7",
            dark: "#0288d1",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          contrastThreshold: 3,
          tonalOffset: 0.2,
          action: {
            hover: "rgba(255, 255, 255, 0.08)",
            hoverOpacity: 0.08,
            selected: "rgba(255, 255, 255, 0.16)",
            selectedOpacity: 0.16,
            disabled: "rgba(255, 255, 255, 0.3)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
            disabledOpacity: 0.38,
            focus: "rgba(255, 255, 255, 0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.24,
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <DialogProvider>
              <Component {...pageProps} />
            </DialogProvider>
          </AuthProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default MyApp;
