import ColorTW from "../utils/ColorTW"

const muiTheme =  {
    "breakpoints": {
        "keys": [
            "xs",
            "sm",
            "md",
            "lg",
            "xl"
        ],
        "values": {
            "xs": 0,
            "sm": 600,
            "md": 900,
            "lg": 1200,
            "xl": 1536
        },
        "unit": "px"
    },
    "direction": "ltr",
    "components": {
        "MuiButton": {
            "defaultProps": {
                "disableElevation": true
            },
            "styleOverrides": {
                "sizeLarge": {
                    "padding": "0.875rem 1rem",
                    "fontSize": "1rem",
                    "lineHeight": 1.3125,
                    "letterSpacing": 0,
                    "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
                    "fontWeight": 700
                },
                "sizeSmall": {
                    "padding": "4px 0px",
                    "@media (min-width:900px)": {
                        "padding": "4px 8px"
                    }
                },
                "containedPrimary": {
                    "backgroundColor": "#007FFF",
                    "color": ColorTW.slate[50]
                }
            }
        },
        "MuiIconButton": {
            "variants": [
                {
                    "props": {
                        "color": "primary"
                    },
                    "style": {
                        "height": 34,
                        "width": 34,
                        "border": "1px solid " + ColorTW.blue[200],
                        "borderRadius": 10,
                        "color": "#66B2FF",
                        "&:hover": {
                            "borderColor": "#173A5E",
                            "background": ColorTW.blue[200] + "66"
                        }
                    }
                }
            ]
        },
        "MuiMenu": {
            "styleOverrides": {
                "paper": {
                    "mt": 0.5,
                    "minWidth": 160,
                    "elevation": 0,
                    "color": "#B2BAC2",
                    "backgroundImage": "none",
                    "bgColor": ColorTW.slate[50],
                    "border": "1px solid " + ColorTW.blue[200],
                    "& .MuiMenuItem-root": {
                        "fontSize": "0.875rem",
                        "fontWeight": 500,
                        "&:hover": {
                            "backgroundColor": ColorTW.blue[200] + "66"
                        },
                        "&:focus": {
                            "backgroundColor": ColorTW.blue[200] + "66"
                        },
                        "&.Mui-selected": {
                            "fontWeight": 500,
                            "color": ColorTW.blue[800],
                            "backgroundColor": ColorTW.blue[200]
                        }
                    }
                }
            }
        },
        "MuiPopover": {
            "styleOverrides": {
                "paper": {
                    "boxShadow": "0px 4px 20px rgba(0, 0, 0, 0.5)"
                }
            }
        },
        "MuiContainer": {
            "styleOverrides": {
                "root": {
                    "@media (min-width:900px)": {
                        "paddingLeft": "16px",
                        "paddingRight": "16px"
                    }
                }
            }
        },
        "MuiDivider": {
            "styleOverrides": {
                "root": {
                    "borderColor": "rgba(194, 224, 255, 0.08)"
                }
            }
        },
        "MuiLink": {
            "defaultProps": {
                "underline": "none"
            },
            "styleOverrides": {
                "root": {
                    "color": "#66B2FF",
                    "fontWeight": 700,
                    "display": "inline-flex",
                    "alignItems": "center",
                    "&:hover": {
                        "color": "#99CCF3"
                    },
                    "&.MuiTypography-body1 > svg": {
                        "marginTop": 2
                    },
                    "& svg:last-child": {
                        "marginLeft": 2
                    }
                }
            }
        },
        "MuiChip": {
            "styleOverrides": {
                "root": {
                    "fontWeight": 500
                },
                "outlined": {
                    "color": ColorTW.slate[900],
                    "backgroundColor": "transparent",
                    "borderColor": "#173A5E"
                },
                "filled": {
                    "border": "1px solid transparent",
                    "color": ColorTW.slate[900],
                    "backgroundColor": "#1E4976",
                    "&:hover": {
                        "backgroundColor": "#173A5E"
                    }
                },
                "deleteIcon": {
                    "color": ColorTW.slate[900],
                    "&:hover": {
                        "color": "#E7EBF0"
                    }
                }
            }
        },
        "MuiList": {
            "styleOverrides": {
                "root": {
                    "padding": 0
                }
            }
        },
        "MuiListItemButton": {
            "styleOverrides": {
                "root": {
                    "padding": "8px",
                    "textTransform": "none",
                    "fontWeight": 500,
                    "fontSize": "0.875rem",
                    "color": "#CDD2D7",
                    "borderRadius": 0,
                    "&:hover": {
                        "backgroundColor": ColorTW.blue[200] + "66"
                    },
                    "&.Mui-selected": {
                        "color": ColorTW.slate[900],
                        "borderRadius": 10,
                        "border": "1px solid",
                        "borderColor": "#0059B2 !important",
                        "backgroundColor": ColorTW.blue[200],
                        "&:hover": {
                            "backgroundColor": "#173A5E"
                        }
                    }
                }
            }
        },
        "MuiSelect": {
            "styleOverrides": {
                "iconFilled": {
                    "top": "calc(50% - .25em)"
                }
            }
        },
        "MuiTab": {
            "defaultProps": {
                "disableTouchRipple": true
            }
        },
        "MuiPaper": {
            "styleOverrides": {
                "root": {
                    "backgroundImage": "none",
                    "backgroundColor": ColorTW.slate[50],
                    "&[href]": {
                        "textDecorationLine": "none"
                    }
                },
                "outlined": {
                    "display": "block",
                    "borderColor": "#1E4976",
                    "backgroundColor": ColorTW.blue[200],
                    "a&, button&": {
                        "&:hover": {
                            "boxShadow": "0px 4px 20px rgba(0, 0, 0, 0.5)"
                        }
                    }
                }
            }
        },
        "MuiTableCell": {
            "styleOverrides": {
                "root": {
                    "padding": "8px 16px",
                    "borderColor": "rgba(194, 224, 255, 0.08)"
                },
                "head": {
                    "color": ColorTW.slate[900],
                    "fontWeight": 700
                },
                "body": {
                    "color": "#B2BAC2"
                }
            }
        },
        "MuiToggleButtonGroup": {
            "styleOverrides": {
                "root": {
                    "backgroundColor": ColorTW.slate[50]
                }
            }
        },
        "MuiToggleButton": {
            "styleOverrides": {
                "root": {
                    "textTransform": "none",
                    "fontWeight": 500,
                    "color": "#CDD2D7",
                    "borderColor": "#1E4976",
                    "&.Mui-selected": {
                        "color": ColorTW.slate[900],
                        "borderColor": "#0059B2 !important",
                        "backgroundColor": ColorTW.blue[200],
                        "&:hover": {
                            "backgroundColor": "#173A5E"
                        }
                    }
                }
            }
        },
        "MuiTooltip": {
            "styleOverrides": {
                "tooltip": {
                    "padding": "5px 9px"
                }
            }
        },
        "MuiSwitch": {
            "styleOverrides": {
                "root": {
                    "width": 32,
                    "height": 20,
                    "padding": 0,
                    "& .MuiSwitch-switchBase": {
                        "&.Mui-checked": {
                            "transform": "translateX(11px)",
                            "color": ColorTW.slate[900]
                        }
                    }
                },
                "switchBase": {
                    "height": 20,
                    "width": 20,
                    "padding": 0,
                    "color": ColorTW.slate[900],
                    "&.Mui-checked + .MuiSwitch-track": {
                        "opacity": 1
                    }
                },
                "track": {
                    "opacity": 1,
                    "borderRadius": 32,
                    "backgroundColor": "#2D3843"
                },
                "thumb": {
                    "flexShrink": 0,
                    "width": "14px",
                    "height": "14px"
                }
            }
        },
        "MuiPaginationItem": {
            "styleOverrides": {
                "root": {
                    "textTransform": "none",
                    "fontWeight": 700,
                    "color": "#CDD2D7",
                    "borderColor": "#1E4976",
                    "&.Mui-selected": {
                        "color": ColorTW.slate[900],
                        "borderColor": "#0059B2 !important",
                        "backgroundColor": ColorTW.blue[200],
                        "&:hover": {
                            "backgroundColor": "#173A5E"
                        }
                    }
                }
            }
        }
    },
    "shape": {
        "borderRadius": 10
    },
    "unstable_strictMode": true,
    "typography": {
        "fontFamily": [
              'Inter',
              'sans-serif',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              '"Helvetica Neue"',
              'Arial',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
        "fontFamilyCode": "Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono,monospace",
        "fontFamilyTagline": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
        "fontFamilySystem": "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
        "fontWeightExtraBold": 800,
        "h1": {
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontSize": "clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)",
            "fontWeight": 800,
            "lineHeight": 1.1142857142857143
        },
        "h2": {
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontSize": "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
            "fontWeight": 800,
            "lineHeight": 1.2222222222222223,
            "color": "#E7EBF0"
        },
        "h3": {
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontSize": "2.25rem",
            "lineHeight": 1.2222222222222223,
            "letterSpacing": 0.2,
            "fontWeight": 400
        },
        "h4": {
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontSize": "1.75rem",
            "lineHeight": 1.5,
            "letterSpacing": 0.2,
            "fontWeight": 400
        },
        "h5": {
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontSize": "1.5rem",
            "lineHeight": 1.5,
            "letterSpacing": 0.1,
            "color": "#66B2FF",
            "fontWeight": 400
        },
        "h6": {
            "fontSize": "1.25rem",
            "lineHeight": 1.5,
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontWeight": 500
        },
        "button": {
            "textTransform": "initial",
            "fontWeight": 700,
            "letterSpacing": 0,
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontSize": "0.875rem",
            "lineHeight": 1.75
        },
        "subtitle1": {
            "fontSize": "1.125rem",
            "lineHeight": 1.3333333333333333,
            "letterSpacing": 0,
            "fontWeight": 500,
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""
        },
        "body1": {
            "fontSize": "1rem",
            "lineHeight": 1.5,
            "letterSpacing": 0,
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontWeight": 400
        },
        "body2": {
            "fontSize": "0.875rem",
            "lineHeight": 1.5,
            "letterSpacing": 0,
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontWeight": 400
        },
        "caption": {
            "display": "inline-block",
            "fontSize": "0.75rem",
            "lineHeight": 1.5,
            "letterSpacing": 0,
            "fontWeight": 700,
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\""
        },
        "htmlFontSize": 16,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        "fontWeightBold": 700,
        "subtitle2": {
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontWeight": 500,
            "fontSize": "0.875rem",
            "lineHeight": 1.57
        },
        "overline": {
            "fontFamily": "Inter,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"",
            "fontWeight": 400,
            "fontSize": "0.75rem",
            "lineHeight": 2.66,
            "textTransform": "uppercase"
        }
    },
    "nprogress": {
        "color": "#3399FF"
    },
    "props": {
        "MuiBadge": {
            "overlap": "rectangular"
        }
    },
    "mixins": {
        "toolbar": {
            "minHeight": 56,
            "@media (min-width:0px) and (orientation: landscape)": {
                "minHeight": 48
            },
            "@media (min-width:600px)": {
                "minHeight": 64
            }
        }
    },
    "shadows": [
        "none",
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
    ],
    "transitions": {
        "easing": {
            "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
            "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
            "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
            "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
        },
        "duration": {
            "shortest": 150,
            "shorter": 200,
            "short": 250,
            "standard": 300,
            "complex": 375,
            "enteringScreen": 225,
            "leavingScreen": 195
        }
    },
    "zIndex": {
        "mobileStepper": 1000,
        "speedDial": 1050,
        "appBar": 1100,
        "drawer": 1200,
        "modal": 1300,
        "snackbar": 1400,
        "tooltip": 1500
    }
}

export default muiTheme